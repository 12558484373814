<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>校区管理</a-breadcrumb-item>
            <a-breadcrumb-item>考试题库</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="common">
                <category ref="category" />
                <div style="flex:1;margin-left:15px">
                    <div class="clearfix table-tools">
                        <div class="buttons">
                            <a-form layout='inline'>
                            <a-form-item>
                                <a-button @click='showEditModal' type="primary" icon="plus" >新增</a-button>
                            </a-form-item>
                            </a-form>
                        </div>
                        <div class="search">
                            <a-form layout='inline' @submit="searchList">
                                <a-form-item>
                                    <a-tooltip placement="topLeft" >
                                        <template slot="title">
                                            <span>题库名称</span>
                                        </template>
                                        <a-input v-model.trim="searchParams.search.keyword"  allowClear placeholder="题库名称" style="width: 180px"></a-input>
                                    </a-tooltip>
                                </a-form-item>
                                <a-form-item>
                                    <a-button @click="searchList" html-type="submit" type="primary" >搜索</a-button>
                                </a-form-item>
                            </a-form>
                        </div>
                    </div>
                    <div class="table">
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                            :columns="columns" :dataSource="list">
                            <template slot="index" slot-scope="text, record , index">
                                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                            </template>
                            <template slot="action" slot-scope="text,record">
                                <a href="javascript:;" >试题管理</a>
                            </template>
                        </a-table>
                    </div>
                    <div class="page">
                        <a-pagination
                        :pageSizeOptions="pageSizeOptions"
                        :total="pageParams.totalCount"
                        showSizeChanger
                        :pageSize="pageParams.perPage"
                        v-model="current"
                        @change="changePage"
                        @showSizeChange="onShowSizeChange"
                        :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                        >
                            <template slot='buildOptionText' slot-scope='props'>
                            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                            <span v-if="props.value==='100'">100条/页</span>
                            </template>
                        </a-pagination>
                    </div>
                </div>
            </div>
        </div>
        <editModal v-if="isEditModal" :item="modalData"/>
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'id',fixed: 'left'},
  { title: '试题名称', dataIndex: 'name', key: 'name'},
  { title: '试题数', dataIndex: 'totle', key: 'totle'},
  { title: '单选数', dataIndex: 'single_choice', key: 'single_choice'},
  { title: '多选数', dataIndex: 'multiple_hoice', key: 'multiple_hoice'},
  { title: '判断数', dataIndex: 'judge', key: 'judge'},
  { title: '问答数', dataIndex: 'questions_and_answers', key: 'questions_and_answers'},
  { title: '填空数', dataIndex: 'completion', key: 'completion'},
  { title: '创建人', dataIndex: 'create_by', key: 'create_by'},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' }}
]
    import editModal from './editModal'
    import category from './category/index'
    import tableMixins from '@/common/mixins/table'

    export default {
        name:'academyExam',
        data() {
            return {
                columns,
                list:[
                    {
                        id:1,
                        name:'【销售】诺访后确认',
                        totle:6,
                        single_choice:2,
                        multiple_hoice:4,
                        judge:0,
                        questions_and_answers:0,
                        completion:0,
                        create_by:'江雪松'
                    }
                ],
                isEditModal:false
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            category,
            editModal
        },
        mixins:[ tableMixins ],
        methods: {
            getList() {},
            changeCategory(item) {
                console.log(item)
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(){
                this.isEditModal = false
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>