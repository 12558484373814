<template>
  <div class="c-side">
    <div class="buttons">
      <a-form layout='inline'>
        <a-form-item v-if="canSave">
          <a-button type="primary" icon="plus" @click="showEditModal($event)">新建分类</a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="list">
      <perfect-scrollbar class="m-scrollbar">
      <div class="item" :class="{ 'active': cateId === item.album_id }" 
        v-for="(item, index) of folders" :key="index" @click="clickCategory(item)">
        <div class="name">
          <div>{{ item.album_name }}</div>
          <span>({{item.album_count || 0}})</span>
        </div>
        <div class="des"></div>
        <div class="btns">
          <div v-if="canUpdate" class="btn btn-parmary" @click="showEditModal($event, item)">编辑</div>
          <div v-if="canDelete" class="btn btn-delete" @click="deleteCategory($event, item)">删除</div>
        </div>
      </div>
      </perfect-scrollbar>
    </div>
    <editModal v-if="visible" :item="modalData"/>
  </div>
</template>
<style lang="scss" scoped>
.m-scrollbar {
  height: calc(100vh - 280px);
}
</style>
<script>
import editModal from './editModal'
import authority from '@/common/mixins/authority'

export default {
  name: 'Category',
  inject: [ 'parent' ],
  provide() {
    return {
      parent: this
    }
  },
  components: {
    editModal
  },

  data() {
    return {
      loading: false,
      cateId: '',
      folders: [],
      visible: false,
      modalData: null,
      authType:['market','xuetang'],
    }
  },
  mixins: [ authority ],
  async created() {
    await this.getList()
    if(this.folders.length > 0){
      this.parent.changeCategory(this.folders[0])
    }
  },
  methods: {
    async getList() {
      if(!this.canIndex){
          return false
      }
      let res = await this.$store.dispatch('classesAlbumAction', this.searchParams)
      this.folders = res.items
      if(res.items.length == 0){
        return false
      }
      if(!this.cateId){
        this.cateId = res.items[0].album_id
      }
    },

    clickCategory(item) {
      this.cateId = item.album_id
      this.parent.changeCategory(item)
    },
    showEditModal(e, item) {
      e.stopPropagation()
      this.modalData = item
      this.visible = true
    },
    hideModal(val) {
      if(val){
        this.getList()
      }
      this.visible = false
    },
    submitModal() {
      this.visible = false
      this.getList()
    },
    deleteCategory(e, item) {
      e.stopPropagation()
      let that = this
      this.$confirm({
        title: `确定要删除该相册?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('classesAlbumDeleteAction', {album_id: item.album_id })
            if (res) {
              that.$message.success('操作成功~')
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    }
  }
}
</script>
