<template>
    <a-modal title="新建题库" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item :label="item.label" v-for="(item, index) of formData" :key="index">
                <a-input v-if="item.type === 'input'" placeholder="请输入" v-decorator="[item.name, { rules: item.rules }]"/>
                <a-radio-group v-else-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                    <!-- <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio> -->
                    <a-radio :value="1">否</a-radio>
                    <a-radio :value="2">是</a-radio>
                </a-radio-group >
                <a-select v-else-if="item.type === 'select'" placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
                    <!-- <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option> -->
                    <a-select-option :value="1">测试1</a-select-option>
                    <a-select-option :value="2">测试2</a-select-option>
                    <a-select-option :value="3">测试3</a-select-option>
                </a-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
const formData = [
  {
    name: 'name',
    label: '题库名称',
    type: 'input',
    rules: [{ required: true, message: '请输入题库名称!' }]
  },
  {
    name: 'gender',
    label: '是否公开',
    type: 'radio',
    rules: [],
    items: {
      data: 'genderList',
      label: 'gender_name',
      value: 'gender_id'
    }
  },
  {
    name: 'type',
    label: '题库分类',
    type: 'select',
    rules: [{ required: true, message: '请选择题库分类' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
]
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        created () {
            this.visible = true
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            handleOk() {
                
            },
            handleCancel(){
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>